import { MODULE_NAME } from '../constants'

export const SET_ACTIVE_INVOICES_TAB = `SET_ACTIVE_${MODULE_NAME}_TAB` as const
export const SET_SHOW_ACTIONS = `${MODULE_NAME}_SET_SHOW_ACTIONS` as const

export const LOAD_INVOICES_REQUEST = `${MODULE_NAME}_LOAD_INVOICES_REQUEST` as const
export const LOAD_INVOICES_SUCCESS = `${MODULE_NAME}_LOAD_INVOICES_SUCCESS` as const
export const LOAD_INVOICES_FAILURE = `${MODULE_NAME}_LOAD_INVOICES_FAILURE` as const

export const LOAD_INVOICE_PREVIEW_REQUEST = `${MODULE_NAME}_LOAD_INVOICE_PREVIEW_REQUEST` as const
export const LOAD_INVOICE_PREVIEW_SUCCESS = `${MODULE_NAME}_LOAD_INVOICE_PREVIEW_SUCCESS` as const
export const LOAD_INVOICE_PREVIEW_FAILURE = `${MODULE_NAME}_LOAD_INVOICE_PREVIEW_FAILURE` as const

export const SET_INVOICE_SIDE_VIEW = `${MODULE_NAME}_SET_INVOICE_SIDE_VIEW`
export const SEND_EMAIL_REQUEST = `${MODULE_NAME}_SEND_EMAIL_REQUEST` as const
export const SEND_EMAIL_SUCCESS = `${MODULE_NAME}_SEND_EMAIL_SUCCESS` as const
export const SEND_EMAIL_FAILURE = `${MODULE_NAME}_SEND_EMAIL_FAILURE` as const

export const ADD_FILTER = `${MODULE_NAME}_ADD_FILTER` as const
export const SET_FILTER = `${MODULE_NAME}_SET_FILTER` as const
export const REMOVE_FILTER = `${MODULE_NAME}_REMOVE_FILTER` as const
export const RESET_FILTERS = `${MODULE_NAME}_RESET_FILTERS` as const
export const ON_CHANGE_FILTERS = `${MODULE_NAME}_ON_CHANGE_FILTERS` as const

export const GET_FILTERS_PARAMS_REQUEST = `${MODULE_NAME}_GET_FILTERS_PARAMS_REQUEST` as const
export const GET_FILTERS_PARAMS_SUCCESS = `${MODULE_NAME}_GET_FILTERS_PARAMS_SUCCESS` as const
export const GET_FILTERS_PARAMS_FAILURE = `${MODULE_NAME}GET_FILTERS_PARAMS_FAILURE` as const
export const INVOICES_FILTER_PARAMS_LS_KEY = `${MODULE_NAME}_INVOICES_FILTER_PARAMS_LS_KEY` as const
