import i18next from 'i18next'

import { IFilter, IFilterOption } from '../../../components/Filters/interfaces'
import { LabelType } from '../../../components/Label/dictionaries'
import { IInvoiceStatusEnum } from '../interfaces'

const makeInvoicesFiltersOptions: (
  products: IFilterOption[],
  partners: IFilterOption[],
  activeFilters: IFilter[]
) => IFilter[] = (products: IFilterOption[], partners: IFilterOption[], activeFilters: IFilter[]): IFilter[] => {
  const [productDefaultValue]: IFilter[] | undefined = activeFilters.filter((item: IFilter) => item.key === 'productId')
  const [partnerDefaultValue]: IFilter[] | undefined = activeFilters.filter((item: IFilter) => item.key === 'partnerId')
  const [invoiceStatusDefaultValue]: IFilter[] | undefined = activeFilters.filter(
    (item: IFilter) => item.key === 'invoiceStatus'
  )

  return [
    {
      active: typeof productDefaultValue !== 'undefined' ? productDefaultValue.active : false,
      key: 'productId',
      value: 'Product',
      options: products,
      componentType: null,
      withSearch: true,
      selectedFilter: typeof productDefaultValue !== 'undefined' ? productDefaultValue.selectedFilter : '',
      isCountable: true
    },
    {
      active: typeof partnerDefaultValue !== 'undefined' ? partnerDefaultValue.active : false,
      key: 'partnerId',
      value: 'Partner',
      options: partners,
      componentType: null,
      selectedFilter: typeof partnerDefaultValue !== 'undefined' ? partnerDefaultValue.selectedFilter : '',
      withSearch: true,
      isCountable: true
    },
    {
      active: typeof invoiceStatusDefaultValue !== 'undefined' ? invoiceStatusDefaultValue.active : false,
      key: 'invoiceStatus',
      value: 'Status',
      componentType: null,
      selectedFilter: typeof invoiceStatusDefaultValue !== 'undefined' ? invoiceStatusDefaultValue.selectedFilter : '',
      withSearch: false,
      options: [
        {
          id: IInvoiceStatusEnum.Draft,
          value: i18next.t('invoices:list.filters.invoiceStatuses.draft'),
          labelType: LabelType.info
        },
        {
          id: IInvoiceStatusEnum.Open,
          value: i18next.t('invoices:list.filters.invoiceStatuses.open'),
          labelType: LabelType.warn
        },
        {
          id: IInvoiceStatusEnum.Completed,
          value: i18next.t('invoices:list.filters.invoiceStatuses.completed'),
          labelType: LabelType.success
        },
        {
          id: IInvoiceStatusEnum.Cancelled,
          value: i18next.t('invoices:list.filters.invoiceStatuses.cancelled'),
          labelType: LabelType.error
        }
      ],
      isCountable: true
    }
  ]
}

export default makeInvoicesFiltersOptions
