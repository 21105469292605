import { z } from 'zod'

import { IRequestPagination } from '@alteos/ui'

import { addNewFilter, removeActiveFilter, resetFilters, setActiveFilter } from '../../../components/Filters/utils'
import { IAction } from '../../../interfaces/store'
import { localStorageService } from '../../../utils/localStorageService'
import { LOGOUT } from '../../Auth/constants'
import { DEFAULT_PAGINATION } from '../constants'
import { IInvoicePreviewResponse, IInvoicesResponse, IInvoicesState, InvoicesTab } from '../interfaces'
import {
  LOAD_INVOICES_FAILURE,
  LOAD_INVOICES_REQUEST,
  LOAD_INVOICES_SUCCESS,
  LOAD_INVOICE_PREVIEW_FAILURE,
  LOAD_INVOICE_PREVIEW_REQUEST,
  LOAD_INVOICE_PREVIEW_SUCCESS,
  SET_INVOICE_SIDE_VIEW,
  SET_SHOW_ACTIONS,
  ADD_FILTER,
  SET_FILTER,
  REMOVE_FILTER,
  RESET_FILTERS,
  INVOICES_FILTER_PARAMS_LS_KEY,
  GET_FILTERS_PARAMS_REQUEST,
  GET_FILTERS_PARAMS_SUCCESS,
  GET_FILTERS_PARAMS_FAILURE,
  SET_ACTIVE_INVOICES_TAB
} from './constants'

export const initialState: IInvoicesState = {
  tabs: { activeTab: InvoicesTab.Groups },
  invoices: [],
  isFetchingInvoices: false,
  isFetchingFilters: false,
  showActionsInvoiceId: null,
  pagination: DEFAULT_PAGINATION,
  errorMessage: null,
  activeSideView: false,
  isFetchingInvoicePreview: false,
  invoicePreview: null,
  filters: localStorageService.getItem(INVOICES_FILTER_PARAMS_LS_KEY, z.array(z.any())) ?? []
}

function reducer(state: IInvoicesState = initialState, action: IAction<any>): IInvoicesState {
  switch (action.type) {
    case LOAD_INVOICES_REQUEST: {
      const { payload }: IAction<IRequestPagination | null> = action

      if (payload === null) {
        return state
      }

      return {
        ...state,
        isFetchingInvoices: true,
        search: payload.query,
        pagination: {
          ...state.pagination,
          limit: payload.limit,
          offset: payload.offset
        }
      }
    }

    case LOAD_INVOICES_SUCCESS: {
      const { payload }: IAction<IInvoicesResponse> = action

      return {
        ...state,
        isFetchingInvoices: false,
        invoices: payload.invoices,
        pagination: {
          ...state.pagination,
          total: payload.count
        }
      }
    }

    case LOAD_INVOICES_FAILURE: {
      return {
        ...state,
        isFetchingInvoices: false,
        pagination: DEFAULT_PAGINATION
      }
    }

    case LOAD_INVOICE_PREVIEW_REQUEST: {
      const { payload }: IAction<IRequestPagination | null> = action

      if (payload === null) {
        return state
      }

      return {
        ...state,
        isFetchingInvoicePreview: true,
        invoicePreview: null
      }
    }

    case LOAD_INVOICE_PREVIEW_SUCCESS: {
      const { payload }: IAction<IInvoicePreviewResponse> = action

      return {
        ...state,
        isFetchingInvoicePreview: false,
        invoicePreview: payload.invoicePreview
      }
    }

    case LOAD_INVOICE_PREVIEW_FAILURE: {
      return {
        ...state,
        isFetchingInvoicePreview: false,
        invoicePreview: null
      }
    }

    case SET_SHOW_ACTIONS: {
      const { payload }: IAction<string | null> = action
      return {
        ...state,
        showActionsInvoiceId: payload || null
      }
    }

    case SET_ACTIVE_INVOICES_TAB:
      return { ...state, tabs: { activeTab: action.payload as InvoicesTab } }

    case SET_INVOICE_SIDE_VIEW: {
      const { payload }: IAction<string | null> = action
      if (payload === null) {
        return {
          ...state,
          activeSideView: false
        }
      }

      return {
        ...state,
        activeSideView: true,
        showActionsInvoiceId: null
      }
    }

    case LOGOUT: {
      return initialState
    }

    case ADD_FILTER:
      return {
        ...state,
        filters: addNewFilter(state.filters!, action.payload)
      }

    case SET_FILTER:
      return {
        ...state,
        filters: setActiveFilter(state.filters!, action.payload)
      }

    case REMOVE_FILTER:
      return {
        ...state,
        filters: removeActiveFilter(state.filters!, action.payload)
      }

    case RESET_FILTERS:
      return {
        ...state,
        filters: resetFilters(state.filters!)
      }

    case GET_FILTERS_PARAMS_REQUEST:
      return {
        ...state,
        isFetchingFilters: true
      }

    case GET_FILTERS_PARAMS_SUCCESS:
      return {
        ...state,
        isFetchingFilters: false,
        errorMessage: null,
        filters: action.payload
      }

    case GET_FILTERS_PARAMS_FAILURE:
      return {
        ...state,
        isFetchingFilters: false,
        errorMessage: action.payload,
        filters: initialState.filters
      }

    default:
      return state
  }
}

export default reducer
