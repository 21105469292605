import { AxiosResponse, isAxiosError } from 'axios'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import { Action } from '@alteos/dictionaries/dist/Action'

import { IFilter } from '../../../components/Filters/interfaces'
import { ToastType } from '../../../dictionaries'
import { IPartner, IProduct } from '../../../interfaces/common'
import { IAction, IStore } from '../../../interfaces/store'
import { triggerErrorPopup } from '../../../store/utils/triggerErrorPopup'
import { localStorageService } from '../../../utils/localStorageService'
import { getUserPermissions, hasPermission, Permissions } from '../../Auth'
import { actions as layoutActions, openSideView } from '../../Layout'
import { isClientError } from '../../Payments/store/sagas/upcoming'
import { policiesApi } from '../../Policies/api'
import { invoicesApi } from '../api'
import { MODULE_NAME } from '../constants'
import {
  IInvoice,
  IInvoicePreviewResponse,
  IInvoiceStatusEnum,
  IInvoicesResponse,
  InvoiceTypeEnum,
  InvoicesTab,
  IFilterNormalizeParams,
  IPartnersResponse,
  IProductsResponse
} from '../interfaces'
import makeInvoicesFiltersOptions from '../utils/makeInvoicesFiltersOptions'
import {
  loadInvoicePreviewSuccess,
  loadInvoicesSuccess,
  openActionsList,
  saveErrorMessage,
  saveInvoiceFilters,
  sendEmailSuccess
} from './actions'
import {
  LOAD_INVOICES_FAILURE,
  LOAD_INVOICES_REQUEST,
  LOAD_INVOICE_PREVIEW_FAILURE,
  LOAD_INVOICE_PREVIEW_REQUEST,
  SEND_EMAIL_FAILURE,
  SEND_EMAIL_REQUEST,
  SET_INVOICE_SIDE_VIEW,
  ON_CHANGE_FILTERS,
  GET_FILTERS_PARAMS_REQUEST,
  INVOICES_FILTER_PARAMS_LS_KEY
} from './constants'

export function* loadInvoices(): Generator {
  try {
    const invoicesState = yield* select((state: IStore) => state[MODULE_NAME])
    const activeFilters = invoicesState.filters!.filter((item: IFilter) => item.selectedFilter !== '')
    const findByKey = (key: string) => (filter: IFilter) => filter.key === key
    const productId = activeFilters.find(findByKey('productId'))
    const partnerId = activeFilters.find(findByKey('partnerId'))
    const invoiceStatus = activeFilters.find(findByKey('invoiceStatus'))

    const response: IInvoicesResponse = yield* call(invoicesApi.loadInvoices, {
      limit: invoicesState.pagination.limit,
      offset: invoicesState.pagination.offset,
      search: invoicesState?.search,
      filter: {
        types:
          invoicesState.tabs.activeTab === InvoicesTab.Groups
            ? [InvoiceTypeEnum.GroupedInvoice]
            : Object.values(InvoiceTypeEnum).filter((type) => type !== InvoiceTypeEnum.GroupedInvoice),
        productId: productId?.selectedFilter as string,
        partnerId: partnerId?.selectedFilter as string,
        invoiceStatus: invoiceStatus?.selectedFilter as IInvoiceStatusEnum
      }
    })

    yield* put(loadInvoicesSuccess(response))
  } catch (error) {
    yield* put(saveErrorMessage('Failed to fetch invoices', LOAD_INVOICES_FAILURE, error))

    if (isAxiosError(error) && isClientError(error)) yield* triggerErrorPopup(error)
    else throw error
  }
}

export function* loadInvoicePreview({ payload }: IAction<{ invoiceId: string }>): Generator {
  try {
    const response: IInvoicePreviewResponse = yield* call(invoicesApi.loadInvoicePreview, payload.invoiceId)

    yield* put(loadInvoicePreviewSuccess(response))
  } catch (error) {
    yield* put(
      saveErrorMessage(`Failed to fetch invoice preview: ${payload.invoiceId}`, LOAD_INVOICE_PREVIEW_FAILURE, error)
    )

    if (isAxiosError(error) && isClientError(error)) return
    else throw error
  }
}

export function* sendEmail({ payload }: IAction<{ invoiceId: string }>): Generator {
  try {
    const invoicesState = yield* select((state: IStore) => state[MODULE_NAME])
    const invoice = invoicesState.invoices.find((invoice) => invoice.id === payload.invoiceId)
    const withPayment = invoice && shouldSendWithPayment(invoice)

    yield* call(invoicesApi.sendEmail, payload.invoiceId, withPayment)

    yield* put(sendEmailSuccess())
    yield* put(
      layoutActions.displayToast({
        message: `The email for the invoice ${payload.invoiceId} is send to the recipients.`,
        type: ToastType.Success
      })
    )
    yield* put(openActionsList(null))
  } catch (error) {
    yield* put(
      saveErrorMessage(`Failed to send the email to the invoice: ${payload.invoiceId}`, SEND_EMAIL_FAILURE, error)
    )

    if (isAxiosError(error) && isClientError(error)) yield* triggerErrorPopup(error)
    else throw error
  }
}

function shouldSendWithPayment(invoice: IInvoice) {
  const isGroupedInvoice = invoice?.type === InvoiceTypeEnum.GroupedInvoice
  const hasSinglePaymentOrder = invoice?.paymentOrders?.length === 1
  const isInvoiceProcessing = invoice.metadata?.processingType === 'invoice'
  const paid = invoice.status === IInvoiceStatusEnum.Completed

  return !isGroupedInvoice && hasSinglePaymentOrder && isInvoiceProcessing && !paid
}

function* loadFiltersParams(): Generator {
  const permissions: Permissions = (yield* select(getUserPermissions)) as Permissions
  let partnersResponse: AxiosResponse<IPartnersResponse> | null = null
  let productsResponse: AxiosResponse<IProductsResponse> | null = null

  const activeFilters: IFilter[] = (yield* select((state: IStore) =>
    state.invoices.filters.filter((item: IFilter) => item.selectedFilter !== '')
  )) as IFilter[]

  try {
    if (hasPermission(permissions, [Action.PartnerGet, Action.PartnerList])) {
      partnersResponse = yield* call(policiesApi.getPartnersList)
    }
    if (hasPermission(permissions, Action.ProductConfigurationList)) {
      productsResponse = yield* call(policiesApi.getProductsList)
    }
  } catch (error: any) {
    yield* put(saveErrorMessage('Failed to fetch filter params', error))
    throw error
  }

  const sortedPartners: IFilterNormalizeParams[] = (partnersResponse?.data.partners ?? [])
    .sort((partnerA: IPartner, partnerB: IPartner) => partnerA.name.localeCompare(partnerB.name))
    .map((partner: IPartner) => {
      return {
        id: partner.id,
        value: partner.name
      }
    })

  const sortedProducts: IFilterNormalizeParams[] = (productsResponse?.data.products ?? [])
    .sort((productA: IProduct, productB: IProduct) => productA.displayName.localeCompare(productB.displayName))
    .map((product: IProduct) => {
      return {
        id: product.id,
        value: product.displayName
      }
    })
  const filters = makeInvoicesFiltersOptions(sortedProducts, sortedPartners, activeFilters)
  localStorageService.setItem(INVOICES_FILTER_PARAMS_LS_KEY, filters)
  yield* put(saveInvoiceFilters(filters))
}

function* watchLoadInvoicesStart(): Generator {
  yield* takeLatest<any>(LOAD_INVOICES_REQUEST, loadInvoices)
}

function* watchLoadInvoicePreviewStart(): Generator {
  yield* takeLatest<any>(LOAD_INVOICE_PREVIEW_REQUEST, loadInvoicePreview)
}

function* watchOpenInvoiceSideView(): Generator {
  yield* takeLatest<any>(SET_INVOICE_SIDE_VIEW, openSideView)
}

function* watchSendEmailStart(): Generator {
  yield* takeLatest<any>(SEND_EMAIL_REQUEST, sendEmail)
}

function* watchFilterChanges(): Generator {
  yield* takeLatest<any>(ON_CHANGE_FILTERS, loadInvoices)
}

function* watchGetFiltersParams(): Generator {
  yield* takeLatest<any>(GET_FILTERS_PARAMS_REQUEST, loadFiltersParams)
}

export default (): Generator[] => [
  watchLoadInvoicesStart(),
  watchOpenInvoiceSideView(),
  watchLoadInvoicePreviewStart(),
  watchSendEmailStart(),
  watchFilterChanges(),
  watchGetFiltersParams()
]
