import defaultApiInstance, { Api } from '../../../services/api/api'
import { IInovicesApi, IInvoicePreviewResponse, IInvoiceRequestPagination, IInvoicesResponse } from '../interfaces'

class InvoicesApi implements IInovicesApi {
  constructor(private api: Api) {}

  loadInvoices = async ({ limit, offset, search, filter }: IInvoiceRequestPagination): Promise<IInvoicesResponse> => {
    const invoicesUrl = 'v1/invoice'
    const params: URLSearchParams = new URLSearchParams([
      ['offset', offset.toString()],
      ['limit', limit.toString()]
    ])

    if (search) {
      params.append('search', JSON.stringify({ policyId: search, invoiceNumber: search }))
    }

    if (filter) {
      params.append('filter', JSON.stringify(filter))
    }

    const response = await this.api.get(invoicesUrl, params)
    return response.data
  }

  loadInvoicePreview = async (invoiceId: string): Promise<IInvoicePreviewResponse> => {
    const invoicePreviewUrl = `v1/invoice/${invoiceId}/preview`
    const response = await this.api.get(invoicePreviewUrl)
    return response.data
  }

  sendEmail = async (invoiceId: string, pay?: boolean): Promise<void> => {
    const invoicesUrl = `v1/invoice/${invoiceId}/send-email?pay=${pay}`
    await this.api.post(invoicesUrl)
  }
}

export const invoicesApi: IInovicesApi = new InvoicesApi(defaultApiInstance)
